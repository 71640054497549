import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { KeyboardAvoidingView, Text, View, TouchableOpacity, ImageBackground, Platform } from 'react-native';
import { Button, Input, Image } from 'react-native-elements';
import { StatusBar } from 'expo-status-bar';
import { MaterialIcons } from '@expo/vector-icons'; 
import { UserContext } from '../components/userContext'
import { auth, db, storage, specialFirestoreOptions } from "../../firebase"
import * as ImagePicker from 'expo-image-picker';
import { showMessage, hideMessage } from "react-native-flash-message";
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { AntDesign } from '@expo/vector-icons';
import Filter from 'react-css-filter'
import ModalWeb from 'modal-enhanced-react-native-web';
import GlobalCameraFeatureStyle from '../utils/globalCameraFeatureStyle'

const RoomChange = ({ navigation }) => {
    const [type, setType] = useState("")
    const [typeClone, setTypeClone] = useState("")
    const [details, setDetails] = useState("")
    const [user, setUser] = useState(auth.currentUser)
    const {userDB, setUserDB} = useContext(UserContext)
    const [img, setImg] = useState(null)
    const [url, setUrl] = useState("")
    const [showModal, setShowModal] = useState(false)
    
    const { t } = useTranslation()

    useLayoutEffect(() => {
      navigation.setOptions({
          title: "RoomChange",
          headerBackTitleVisible: false,
          headerTitleAlign: "right",
          headerTitle: () =>(
              <View style={{flexDirection: "row", alignItems: "center"}}>
                  <MaterialIcons name="room-preferences" size={24} color="black" />                
                  <Text style={{ color: "black", fontWeight : "bold", fontSize: 20, marginLeft: 5}}>{t('delogement')}</Text>
              </View>
          ),
          headerLeft: () => (
              <TouchableOpacity onPress={() => {
              navigation.navigate("My Sweet Hotel")}}>
                  <AntDesign name="left" size={24} color="black" style={{marginLeft: 5}} />
              </TouchableOpacity>
          )
      })
  }, [navigation])

    useEffect(() => {
        (async () => {
          if (Platform.OS !== 'web') {
            const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
            if (status !== 'granted') {
              alert('Sorry, we need camera roll permissions to make this work!');
            }
          }
        })();
      }, []);
      
      const pickImage = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.All,
          allowsEditing: true,
          aspect: [4, 3],
          quality: 1,
        });
    
        console.log(result);
    
        if (!result.cancelled) {
          setImg(result.uri);
          showMessage({
            message: t('photo_selectionnee_message'),
            type: "info",
          })
        }
      };
  

      const handleChangePhotoUrl = async(event) => {
        event.preventDefault()
        const response = await fetch(img)
        const blob = await response.blob()

        const uploadTask = storage.ref(`msh-photo-roomChange/${type}`).put(blob)
        uploadTask.on(
          "state_changed",
          snapshot => {},
          error => {console.log(error)},
          () => {
            storage
              .ref("msh-photo-roomChange")
              .child(type)
              .getDownloadURL()
              .then(url => {
                const uploadTask = () => {
                    setType('')
                    setTypeClone('')
                    setDetails('')

                   return db.collection("hotels")
                    .doc(userDB.hotelId)
                    .collection('roomChange')
                    .add({
                        author: "effectué par le client",
                        date: new Date(),
                        client: user.displayName,
                        fromRoom: userDB.room,
                        toRoom: "",
                        state: "",
                        reason: type,
                        reasonClone: typeClone, 
                        details: details,
                        markup: Date.now(),
                        img: url,
                        status: true,
                        userId: userDB.userId
                    }).then(function(docRef){
                        console.log(docRef.id)
                    }).catch(function(error) {
                        console.error(error)
                    })
                }
                  return setUrl(url, uploadTask())})
          }
        )
      } 

    const handleSubmit = (event) => {
      event.preventDefault()
      setType('')
      setTypeClone('')
      setDetails('')
      return db.collection("hotels")
              .doc(userDB.hotelId)
              .collection('roomChange')
              .add({
                  author: "effectué par le client",
                  date: new Date(),
                  client: user.displayName,
                  fromRoom: userDB.room,
                  toRoom: "",
                  state: "",
                  reason: type,
                  reasonClone: typeClone, 
                  details: details,
                  markup: Date.now(),
                  img: url,
                  status: true,
                  userId: userDB.userId
              }).then(function(docRef){
                  console.log(docRef.id)
              }).catch(function(error) {
                  console.error(error)
              })
    } 

    const handleItemToJourney = () => {
      return db.collection("guestUsers")
      .doc(user.uid)
      .collection('journey')
      .doc(userDB.journeyId)
      .update({
          roomChange: specialFirestoreOptions.arrayUnion({
            reason: type,
            details: details,
            markup: Date.now()
          })
      })
  }

    return (
        <KeyboardAvoidingView behavior="padding" style={GlobalCameraFeatureStyle.container}>
             <StatusBar style="light" />
            <View style={GlobalCameraFeatureStyle.containerText}>
            <ImageBackground source={ require('../../img/pic_roomChange.png') } style={{
                flex: 1,
                width: "100%"}}>
                </ImageBackground>
            </View>
            <View style={GlobalCameraFeatureStyle.inputContainer}>
                <TouchableOpacity style={{width: "100%"}} onPress={() => setShowModal(true)}>
                  <Input style={{ outline: "none" }} placeholder={typeClone !== "" ? typeClone : t('motif_delogement')} type="text" value={typeClone} 
                  onChangeText={(text) => setTypeClone(text)} />
                </TouchableOpacity>
                <Input style={{ outline: "none" }} placeholder={t('details')}  type="text" value={details} 
                onChangeText={(text) => setDetails(text)} />
            </View>
            <View style={{marginBottom: 55, marginTop: 25}}>
                <TouchableOpacity style={{flexDirection: "row", width: 300, alignItems: "center", justifyContent: "center"}} onPress={pickImage}>
                <MaterialIcons name="add-a-photo" size={24} color="grey" />                    
                <Text style={{fontSize: 20, color: "grey", marginLeft: 10}}>{t('ajout_photo')}</Text>
                </TouchableOpacity>
            </View>
            <Button onPress={(event) => {
              if(img !== null) {
                  handleChangePhotoUrl(event)
                  handleItemToJourney()
                  showMessage({
                    message: t('delogement_message_succes'),
                    type: "success"
                })
              }else{
                handleSubmit(event)
                handleItemToJourney()
                showMessage({
                  message: t('delogement_message_succes'),
                  type: "success"
                })
              }
            }} containerStyle={GlobalCameraFeatureStyle.button} title={t('delogement_bouton')} />

          <ModalWeb 
          animationType="slide"
          transparent={true}
          isVisible={showModal} 
          style={GlobalCameraFeatureStyle.roomBoxView}>
              <View style={GlobalCameraFeatureStyle.modalRoom}>
                  <Text style={{
                      width: "100%", 
                      fontSize: 20,
                      paddingBottom: 10,
                      textAlign: "center",
                      fontWeight: "bold",
                      paddingTop: 10,
                      backgroundColor: "lightgrey",
                      borderTopLeftRadius: 15,
                      borderTopRightRadius: 15
                      }}>{t('choose_reason')}</Text>
                      <View style={{width: "100%", alignItems: "center"}}>
                        <TouchableOpacity style={{width: "100%"}} onPress={() => {
                          setTypeClone(t('msh_room_change.r_reason.r_noise'))
                          setType("noise")
                          setShowModal(false)
                        }}>
                          <Text style={{textAlign: "center", paddingBottom: 10, paddingTop: 10, width: "100%", borderTopColor: "lightgrey", borderTopWidth: 1}}>{t('msh_room_change.r_reason.r_noise')}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{width: "100%"}} onPress={() => {
                          setTypeClone(t('msh_room_change.r_reason.r_temperature'))
                          setType("temperature")
                          setShowModal(false)
                        }}>
                          <Text style={{textAlign: "center", paddingBottom: 10, paddingTop: 10, width: "100%", borderTopColor: "lightgrey", borderTopWidth: 1}}>{t('msh_room_change.r_reason.r_temperature')}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{width: "100%"}} onPress={() => {
                          setTypeClone(t('msh_room_change.r_reason.r_maintenance'))
                          setType("maintenance")
                          setShowModal(false)
                        }}>
                          <Text style={{textAlign: "center", paddingBottom: 10, paddingTop: 10, width: "100%", borderTopColor: "lightgrey", borderTopWidth: 1}}>{t('msh_room_change.r_reason.r_maintenance')}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{width: "100%"}} onPress={() => {
                          setTypeClone(t('msh_room_change.r_reason.r_cleaning'))
                          setType("cleaning")
                          setShowModal(false)
                        }}>
                          <Text style={{textAlign: "center", paddingBottom: 10, paddingTop: 10, width: "100%", borderTopColor: "lightgrey", borderTopWidth: 1}}>{t('msh_room_change.r_reason.r_cleaning')}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{width: "100%"}} onPress={() => {
                          setTypeClone(t('msh_room_change.r_reason.r_others'))
                          setType("others")
                          setShowModal(false)
                        }}>
                          <Text style={{textAlign: "center", paddingBottom: 10, paddingTop: 10, width: "100%", borderTopColor: "lightgrey", borderTopWidth: 1}}>{t('msh_room_change.r_reason.r_others')}</Text>
                        </TouchableOpacity>
                      </View>
              </View>
          </ModalWeb>

        </KeyboardAvoidingView>
    )
}

export default RoomChange