const fr = {
    monthNames: ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'],
    monthNamesShort: ['Janv.','Févr.','Mars','Avril','Mai','Juin','Juil.','Août','Sept.','Oct.','Nov.','Déc.'],
    dayNames: ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'],
    dayNamesShort: ['Dim.','Lun.','Mar.','Mer.','Jeu.','Ven.','Sam.'],
    today: 'Aujourd\'hui'
  };

const pt = {
    monthNames: ['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro' ],
    monthNamesShort: ['Jan','Fev','Março','Abril','Maio','Junho','Jul','Ago','Set','Out','Nov .','Dez .'],
    dayNames: ['Domingo','Segunda-feira','Terça-feira','Quarta-feira','Quinta-feira','Sexta-feira','Sábado'],
    dayNamesShort: ['Dom.', 'Seg.', 'Ter.', 'Qua.', 'Qui.', 'Sex.', 'Sáb.'],
    today: 'hoje'
};

const en = {
    monthNames: ['January','February','March','April','May','June','July','August','September','October','November','December' ],
    monthNamesShort: ['Jan','Feb','March','April','May','June','Jul','Aug','Sept','Oct','Nov .','Dec.'],
    dayNames: ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
    dayNamesShort: ['Sun.','Mon.','Tues.','Wed.','Thu.','Fri.','Sat.'],
    today: 'Today'
};

const es = {
    monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ],
    monthNamesShort: ['Ene', 'Feb', 'March', 'Abril', 'May', 'June', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov ', 'Dic .'],
    dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    dayNamesShort: ['domingo','lunes','martes','miércoles','jueves','viernes','sábado'],
    today: 'Hoy'
};

const de = {
    monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember' ],
    monthNamesShort: ['Jan','Feb','März','April','Mai','Juni','Jul','Aug','Sept','Oct','Nov .','Dez .'],
    dayNames: ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag'],
    dayNamesShort: ['So.','Mo.','Di.','Mi.','Do.','Fr.','Sa.'],
    today: 'heute'
};

const it = {
    monthNames: ['gennaio','febbraio','marzo','aprile','maggio','giugno','luglio','agosto','settembre','ottobre','novembre','dicembre' ],
    monthNamesShort: ['Gen','Feb','March','April','May','June','Jul','Aug','Sett','Oct','Nov .','Dec .'],
    dayNames: ['domenica','lunedì','martedì','mercoledì','giovedì','venerdì','sabato'],
    dayNamesShort: ['Sun.','Lun.','Mart.','Mer.','Giov.','Ven.','Sab.'],
    today: 'Oggi'
};

export { fr, pt, en, de, es, it }